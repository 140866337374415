
import { getConfigAsync, clearProfile } from 'utilities/helper';
import * as LangManager from 'utilities/LangManager';


/*
 * ======================================================================================
 * Required global variables.
 */
if (typeof g_server_root === 'undefined') {
    window.g_server_root = window.location.origin;
}


/*
 * ======================================================================================
 * Import external dependencies
 */
import Vue from 'vue';
import VueI18n from 'vue-i18n'
Vue.use(VueI18n);

import axios from 'axios'
Vue.prototype.$http = axios; /* Simply the usage of axios */

import '@/assets/js/Common.js';


/*
 * ======================================================================================
 * Register global Vue components
 */
// import VCodeImage from '@/pages/Components/VCodeImage.vue';
import SmsVerification from '@/pages/Components/SmsVerification.vue';
import SubmitButton from '@/pages/Components/SubmitButton.vue';
// Vue.component('vcode-image', VCodeImage);
Vue.component('sms-verification', SmsVerification);
Vue.component('submit-button', SubmitButton);


/*
 * ======================================================================================
 * Global functions
 */
const s_supportedPropKeys = Object.freeze(['requireAuthForNotices']);
$.extend({
    /**
     * Calls a server API using GET method.
     * @param {any} self A valid Vue component with the destroyed property defined.
     * @param {string} api_path The relative path of a server API.
     * @returns
     */
    callGetApi: async function (self, api_path) {
        try {
            const resp = await axios.get(g_server_root + api_path);
            return resp.data;
        } catch (err) {
            let handled = false;
            if (err.response) {
                const resp = err.response;
                if (resp.status === 401) {
                    clearProfile();

                    // Requires authentication.
                    if (self.$route.path !== '/user/login')
                        self.$router.push({ path: '/user/login', query: { from: self.$route.path } });
                    handled = true;
                } else if (resp.data && typeof resp.data.errcode === 'number') {
                    return resp.data;
                }
            }
            if (!handled) {
                throw err;
            }
        }
    },
    callDeleteApi: async function (self, api_path) {
        try {
            const resp = await axios.delete(g_server_root + api_path);
            return resp.data;
        } catch (err) {
            let handled = false;
            if (err.response) {
                const resp = err.response;
                if (resp.status === 401) {
                    clearProfile();

                    // Requires authentication.
                    if (self.$route.path !== '/user/login')
                        self.$router.push({ path: '/user/login', query: { from: self.$route.path } });
                    handled = true;
                } else if (resp.data && typeof resp.data.errcode === 'number') {
                    return resp.data;
                }
            }
            if (!handled) {
                throw err;
            }
        }
    },
    /**
     * Calls a server API using POST method.
     * @param {any} self A valid Vue component with the destroyed property defined.
     * @param {string} api_path The relative path of a server API.
     * @param {any} data The data to be posted
     * @returns 
     */
    callPostApi: async function (self, api_path, data) {
        try {
            const resp = await axios.post(g_server_root + api_path, data);
            return resp.data;
        } catch (err) {
            let handled = false;
            if (err.response) {
                const resp = err.response;
                if (resp.status === 401) {
                    clearProfile();

                    // Requires authentication.
                    if (self.$route.path !== '/user/login')
                        self.$router.push({ path: '/user/login', query: { from: self.$route.path } });
                    handled = true;
                } else if (resp.data && typeof resp.data.errcode === 'number') {
                    return resp.data;
                }
            }
            if (!handled) {
                throw err;
            }
        }
    },

    setConfig: function (config) {
        // The name must be specified for the config.
        if (!config || typeof config.name !== 'string') {
            throw new Error('Requires a valid config instance.')
        }

        Vue.prototype.sysconfig = config;
    },

    /**
     * Set customized property for the Vue application.
     * @param {string} key The key of the property.
     * @param {*} val The value of the property.
     */
    setProp: function (key, val) {
        if (!key || typeof key !== 'string' || s_supportedPropKeys.indexOf(key) < 0)
            throw new Error(`Invalid prop key: ${key}`);
        Vue.prototype[key] = val;
    },

    changeLang: function (vue_comp, lang) {
        console.log(`## Change lang to ${lang}`);
        LangManager.loadLanguageAsync(vue_comp.$i18n, lang);
    },

    scanPopup: function () {
        $('a[data-popup-target]').each((index, elm) => {
            $(elm).off('click').on('click', () => {
                const href = $(elm).attr('data-popup-target');

                Vue.nextTick(() => {
                    $('.page.support-iframe .iframe-container iframe').attr('src', href);
                    $('.page.support-iframe .iframe-container').removeClass('d-none');
                });
            });
        });
        $('a[data-download-url]').each((index, elm) => {
            $(elm).off('click').on('click', () => {
                const href = $(elm).attr('data-download-url');

                Vue.nextTick(() => {
                    const body = $('body');
                    const frm = $('<form class="d-none" method="get"></form>')
                    frm.appendTo(body);
                    frm.attr('action', href);
                    frm.trigger('submit');

                    // Remove the form finally
                    frm.remove();
                });
            });
        });
    }
});


/* Define routes */
import VueRouter from 'vue-router';


/**
 * Init app
 */
function initVue(entry, i18n, routes) {
    $.extend({
        getLocaleMessage: function (key) {
            return i18n.t(key);
        }
    });

    Vue.use(VueRouter);

    /*
     * ======================================================================================
     * Create Vue instance
     */
    const router = new VueRouter({ /* mode: 'history', */ routes: routes });
    new Vue({
        el: '#app',
        template: '<entry/>',
        components: { entry },
        router,
        i18n
    });
}


async function initLocalizedResourcesAsync() {
    // Make sure the system configuration is available first.
    const config = await getConfigAsync();
    $.setConfig(config);

    Vue.prototype.currencySymbol = config.currencySymbol || 'USDT';
    Vue.prototype.getLocaleName = function (lang) {
        return LangManager.getLocaleName(lang);
    }
    return await LangManager.initLocalizedResourcesAsync(config);
}


/**
 * ======================================================================================
 * The main entry of the application.
 */
export const initApp = function (entry, routes, version) {
    // Save the current version of the app.
    Vue.prototype.$version = version;

    initLocalizedResourcesAsync().then(i18n => {
        // Start with an unauthorized profile.
        clearProfile();

        // Initialize Vue pages.
        initVue(entry, i18n, routes);
    });
};