<template>
    <section class="page help-page support-iframe pb-5">
        <div class="container">
            <div class="row">
                <div class="col">
                    <!-- loader -->
                    <loading-indicator v-if="!notice" />

                    <!-- notice -->
                    <template v-else>
                        <h1 class="page-title" v-html="notice.title"></h1>
                        <h2 class="sub-title">
                            {{ notice.author }}
                            {{ new Date(notice.timeCreated).formatDate() }}
                        </h2>
                        <pre class="agreement" v-html="notice.body"></pre>
                    </template>
                </div>
            </div>
            <div class="row" v-if="all_notices && all_notices.length > 0">
                <div class="col">
                    <div class="title">
                        <div class="text">{{$t('notices.message_list')}}</div>
                    </div>
                    <ul class="notice-list">
                        <li v-for="entity in paged_notices" :key="entity.id" class="d-flex">
                            <div class="img">
                                <img  src="@/assets/images/announcement.png" alt="" />
                            </div>  
                            <div class="item">
                                <router-link :to="'/notice/' + entity.id"><span v-html="entity.title"></span></router-link>
                                <div class="text-nowrap">{{ new Date(entity.timeCreated).formatDate() }}</div>
                            </div>    
                        </li>
                    </ul>
                    <vue-pager :total="all_notices.length" :page_size="page_size" :page_index="page_index" @page-changed="onPageChanged" />
                </div>
            </div>
        </div>

        <ext-resource-i-frame />
    </section>
</template>

<script type="text/javascript">
import ExtResourceIFrame from '../Components/ExtResourceIFrame.vue';
import VuePager from '../Components/VuePager.vue';

export default {
    components: { ExtResourceIFrame, VuePager },
    props: ['id'],

    data: function () {
        return {
            notice: null,
            all_notices: [],

            // The current page index
            page_index: 0,
            page_size: 10
        };
    },

    computed: {
        paged_notices: function () {
            const input = this.all_notices;
            if (input && input.length > 0) {
                const minPageIndex = this.page_index * this.page_size;
                const maxPageIndex = (this.page_index + 1) * this.page_size;

                const output = input.filter((elm, index) => {
                    return index >= minPageIndex && index < maxPageIndex;
                });
                console.log(`#### output length = ${output.length}`);
                return output;
            }

            return [];
        }
    },

    mounted() {
        this.readNoticeFromServer(this.id);
        this.readAllNotices();
    },

    methods: {
        readNoticeFromServer: function (id) {
            const self = this;
            self.notice = null;

            setTimeout(() => {
                $.callGetApi(self, '/api/v1/notice?id=' + encodeURIComponent(id)).then((json) => {
                    if (json.errcode === 0) {
                        self.notice = json.data;

                        Vue.nextTick(() => {
                            $.scanPopup();
                        });
                    } else {
                        self.$router.push('/notfound');
                    }
                });
            }, 10);
        },

        readAllNotices: function () {
            const self = this;
            $.callGetApi(self, '/api/v1/notices').then((json) => {
                if (json.errcode === 0 && json.data) {
                    self.page_index = 0;
                    self.all_notices = Object.freeze(json.data);
                }
            });
        },

        onPageChanged: function (pageIndex) {
            this.page_index = pageIndex;
        }
    },

    watch: {
        $route() {
            this.readNoticeFromServer(this.id);
        },

        /**
         * Update notice content after the language is changed.
         */
        '$i18n.locale': function (newVal, oldVal) {
            this.readNoticeFromServer(this.id);

            // BUGFIX:
            // Read the notice list for the specified language.
            this.readAllNotices();
        }
    }
};
</script>
<style scoped>
.notice-list{
    list-style:none;
    margin-left: 5px;
}   
.notice-list .d-flex{
    align-items: center;
}
.img{
    margin-right: 10px;
}
.img img{
    width: 30px;
    height: 30px;
}
.notice-list a div{
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #000922;
}
.notice-list .item .text-nowrap{
    padding-left: 0px;
    font-size: 14px;
} 
.notice-list li{
    border-bottom: 1px solid #F0F0F2 ;
}
.title{
    height: 40px;
    background: #F5F5F7 ;
    display: flex;
    align-items: center;
}
.title .text{
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #000922;
}
</style>>