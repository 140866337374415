<template>
    <div class="content-top">
        <div class="futures-trade" id="futures-trade1">
            <div class="order-books">
                <OrderBooks :symbol="symbol"  :order_books="order_books" :showTradeEvent="showTradeEvent" @clickEvent="onPriceSelected"/>
            </div>
        </div>
        <div class="left">
            <div class="trading-chart">
                <div class="ticker bg-title text-content">
                    <div stop="1" class="symbol-name">
                        <div class="d-flex flex-row">
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {{ $t('general.futures') }}
                                </button>
                                <div class="dropdown-menu">
                                    <router-link to="/futures" class="dropdown-item">{{ $t('general.futures')
                                        }}</router-link>
                                    <router-link to="/exchange" class="dropdown-item">{{ $t('general.exchange')
                                        }}</router-link>
                                    <router-link v-if="sysconfig.bOptionEnabled" to="/boption" class="dropdown-item">{{
                                        $t('general.boption') }}</router-link>
                                </div>
                            </div>
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {{ symbol.metadata.name }}
                                </button>
                                <div class="dropdown-menu  side_right">
                                    <div class="menu_contet">
                                        <div class="input-group">
                                            <div class="input-group-text">
                                                <svg viewBox="0 0 1024 1024" width="32" height="32">
                                                    <path
                                                        d="M945.066667 898.133333l-189.866667-189.866666c55.466667-64 87.466667-149.333333 87.466667-241.066667 0-204.8-168.533333-373.333333-373.333334-373.333333S96 264.533333 96 469.333333 264.533333 842.666667 469.333333 842.666667c91.733333 0 174.933333-34.133333 241.066667-87.466667l189.866667 189.866667c6.4 6.4 14.933333 8.533333 23.466666 8.533333s17.066667-2.133333 23.466667-8.533333c8.533333-12.8 8.533333-34.133333-2.133333-46.933334zM469.333333 778.666667C298.666667 778.666667 160 640 160 469.333333S298.666667 160 469.333333 160 778.666667 298.666667 778.666667 469.333333 640 778.666667 469.333333 778.666667z"
                                                        fill="#666666" p-id="2868"></path>
                                                </svg>
                                            </div>
                                            <input type="text" class="form-control" v-model="search_term"
                                                placeholder="" />
                                        </div>
                                        <div class="futures-content">
                                            <router-link v-for="sym in filteredSymbols" :key="sym.id"
                                                :to="'/futures/' + sym.symbol.toLowerCase()" class="dropdown-item">
                                                {{ sym.name }}
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div growing-ignore="true" class="price-container"
                        :class="{ 'color-up': symbol.up, 'color-down': !symbol.up }">
                        <div>{{ symbol.price }}</div>
                        <div class="price-change">{{ symbol.price_change }}</div>
                    </div>
                    <dl growing-ignore="true" class="change" id="futures-change">
                        <dt class="text-label">{{ $t('futures.24h_change') }}</dt>
                        <dd v-bind:class="symbol.up ? 'color-up' : 'color-down'">{{ symbol.price_change_pct }}</dd>
                    </dl>
                    <dl growing-ignore="true" class="low d-none d-sm-block">
                        <dt class="text-label">{{ $t('futures.24h_low') }}</dt>
                        <dd>{{ symbol.day_low }}</dd>
                    </dl>
                    <dl growing-ignore="true" class="high d-none d-sm-block">
                        <dt class="text-label">{{ $t('futures.24h_high') }}</dt>
                        <dd>{{ symbol.day_high }}</dd>
                    </dl>
                    <dl growing-ignore="true" class="amount d-none d-sm-block">
                        <dt class="text-label">{{ $t('futures.24h_vol') }}</dt>
                        <dd>{{ symbol.day_vol }}</dd>
                    </dl>
                    <div class="goto-chart" @click="showChart"><i class="fa fa-chart-bar"></i></div>
                </div>
                <div class="trading-chart-container bg-content">
                    <div class="chart-container">
                        <div id="tv_chart_container" class="chart">
                            <!-- Display the loading indicator by default -->
                            <loading-indicator />
                        </div>
                    </div>
                </div>
            </div>
            <div class="trade bg-content" id="futures-trade3">
                <div class="mod trade-panel">
                    <div class="mod-head tabs bg-title">
                        <span data-trade-type="exchange" class="mock-a cur">
                            {{ $t('futures.label_trade') }}
                        </span>
                        <div class="actions">
                            <router-link to="/user/balance">{{ $t('general.deposit') }}</router-link>
                        </div>
                    </div>

                    <!-- create orders here -->
                    <div class="create-order-box">
                        <create-order-panel ref="createOrderPanelBuy" :symbol="symbol" :futuresConfig="futuresConfig" :buyUpControl="buyUpControl" :indexOrderPanel="0"
                        @create-order="onCreateOrder" />
                        <create-order-panel ref="createOrderPanelSell" :symbol="symbol" :futuresConfig="futuresConfig" :buyUpControl="!buyUpControl" :indexOrderPanel="4"
                        @create-order="onCreateOrder" />
                    </div>
                </div>
            </div>
            <div class="bottom-trade">
                <div class="row">
                    <div class="col">
                        <button class="btn-submit bg-buy" @click="showTrade(true)">{{ $t('futures.label_buy_up')
                            }}</button>
                    </div>
                    <div class="col">
                        <button class="btn-submit bg-sell" @click="showTrade(false)">{{ $t('futures.label_buy_down')
                            }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="futures-trade">
            <div class="quotes container" id="futures-trade1">
                <inline-svg-icons :symbols="all_symbols" />
                <div class="content">
                    <!-- symbols -->
                    <div class="table_data">
                        <div class="col">
                            <symbol-list ref="quotes" :title="$t('general.exchange')" :symbols="all_symbols" :typeFilter="3"  @symbol-selected="gotoTradePage" />
                        </div>
                    </div>
                 </div>
            </div>
            <div class="order-books" id="futures-trade2">
                <OrderBooks :symbol="symbol"  :order_books="order_books" :showTradeEvent="showTradeEvent" @clickEvent="onPriceSelected"/>
            </div>
            <div class="trade bg-content" id="futures-trade4">
                <div class="mod trade-panel">
                    <div class="mod-head tabs bg-title">
                        <span data-trade-type="exchange" class="mock-a cur">
                            {{ $t('futures.label_trade') }}
                        </span>
                        <div class="actions">
                            <router-link to="/user/balance">{{ $t('general.deposit') }}</router-link>
                        </div>
                    </div>

                    <!-- create orders here -->
                    <create-order-panel ref="createOrderPanel" :symbol="symbol" :futuresConfig="futuresConfig" @create-order="onCreateOrder" :buyUpControl="buyUpControl" @update:buyUpControl="updateBuyUpControl"/>
                </div>
            </div>
        </div>
        
        <!-- Have to wrap the modal with a section element to avoid layout conflict with other elements: .content-top > div -->
        <section>
            <!-- order confirmation modal -->
            <order-confirmation-modal ref="createOrderModal" :symbol="symbol" />
        </section>
    </div>
</template>

<script>
import { getSymbolManagerAsync } from 'utilities/helper';
import * as chart from 'utilities/QuoteDataFeed.js';
import { Dropdown } from 'bootstrap';
import CreateOrderPanel from './Futures_CreateOrderPanel.vue';
import OrderConfirmationModal from './Futures_OrderConfirmationModal.vue';
import FuturesQuotes from './FuturesQuotes.vue';
import { SymbolInfo } from "utilities/SymbolInfo.js";
//symbols-svg-icon
import InlineSvgIcons from '../../Components/_InlineSvgIcons.vue';
import SymbolList from '@/pages/Components/SymbolList.vue';
import OrderBooks from '@/pages/Components/OrderBooks.vue';
// The date time when the last quote is received.
let g_lastQuoteTime = new Date();
//read all_symbols
let g_symbolMap = {};
let g_destoryed = false;
let g_quoteTimerId = 0;
export default {
    components: { CreateOrderPanel, OrderConfirmationModal, FuturesQuotes, InlineSvgIcons ,SymbolList,OrderBooks},

    props: ['symbol', 'futuresConfig'],

    data() {
        return {
            showTradeEvent:false,
            order_books: {
                asks: [],
                bids: []
            },
            symbols: [],
            chartVersion: 0,
            search_term: null,
            // Indicates whether the component has been destoyed or not.
            destroyed: false,

            // All symbols.
            all_symbols: null,
            //buyUpControl
            buyUpControl:true
        };
    },

    created() {
        this.destroyed = false;
        this.getFuturesSymbols();

        // clear all existing symbols
        g_symbolMap = {};
        this.initPageAsync();
    },

    beforeDestroy() {
        console.log(`## Destroy the futures page.`);
        this.destroyed = true;
        clearTimeout(g_quoteTimerId);
        // Stop socket io connections
        $.closeSocketIo();
        chart.destroyChart();
    },
    computed: {
        filteredSymbols: function () {
            if (!this.symbols) {
                return [];
            }
            const term =
                this.search_term && typeof this.search_term === 'string'
                    ? this.search_term.trim().toUpperCase() // Always use upper case
                    : '';
            if (term && term.length > 0) {
                let data = this.symbols.filter((s) => {
                    return s.name.indexOf(term) >= 0;
                });
                return data
            } else {
                return this.symbols
            }
        },
        // return a list of prompted symbols
        promptedSymbols: function () {
            let arr = this.all_symbols.filter(function (s) {
                return s.metadata.prompted && !s.metadata.testOnly;
            });
            return arr;
        }
    },
    mounted() {
        this.initChartAsync();
        const dropdownElementList = document.querySelectorAll('.dropdown-toggle')
        const dropdownList = [...dropdownElementList].map(elm => {
            $(elm).click(() => {
                const d = Dropdown.getOrCreateInstance(elm);
                d.show();
            });
        });
    },

    methods: {
        gotoTradePage: function (sym) {
            let route = sym.getRoutePath();
        },
        updateBuyUpControl(newValue) {
            this.buyUpControl = newValue;
        },
        initChartAsync: async function () {
            const sym = this.symbol;
            const self = this;

            // Clear any existing klines
            chart.setInitialKlines(null);

            // Read initial klines
            const end_ts = Math.floor(new Date().setSeconds(0, 0) / 1000);
            const from_ts = end_ts - 1500 * 60; // request klines for past 1500 minutes.
            const request_url = `/api/v1/quotation/klines?id=${encodeURIComponent(sym.metadata.id)}&type=1&from=${from_ts}&to=${end_ts}&limit=1500`;

            try {
                const resp = await $.callPostApi(self, request_url);
                if (resp) {
                    chart.setInitialKlines(resp);
                }
            } catch (err) {
                console.log(`Failed to read initial klines: ${err}`);
            }

            // Initialize the tradingview chart
            chart.initializeChart(sym, {
                region: this.sysconfig.region,
                locale: this.$i18n.locale,
                uiVersion: this.$version,
                defaultInterval: this.futuresConfig.defaultInterval,
                tzOffset: this.sysconfig.tzOffset
            });

            // Start a socket io connection
            g_lastQuoteTime = new Date();
            const chartVersion = ++this.chartVersion;

            $.initSocketIo(
                '/f' + this.symbol.metadata.id,
                (quote) => {
                    if (self.destroyed === false) {
                        if (self.chartVersion !== chartVersion) {
                            return;
                        }

                        // Is there a big gap for the latest quote?
                        let now = new Date();
                        const gap = now - g_lastQuoteTime;
                        if (gap > 300 * 1000) {
                            setTimeout(() => {
                                console.log('#### rebuild chart ######');
                                $.closeSocketIo();
                                self.initChartAsync();
                            }, 0);
                        } else {
                            g_lastQuoteTime = now;

                            // Update quote.
                            sym.updateRtqs(quote);
                            chart.updateRtqsToChart(quote);

                            // Notify that the price is updated.
                            self.$emit('price-changed', quote);
                        }
                    }
                },
                (depth) => {
                    // Parse depth data
                    self.updateOrderBooks(depth);
                }
            );
        },

        parseDepth: function (src, key_prefix) {
            const pricePrecison = this.symbol.metadata.pricePrecision;
            const amountPrecison = this.symbol.metadata.volumePrecision;

            let total_vol = 0;
            const arr = [];
            for (let i = 0; i < src.length; i++) {
                let item = src[i];
                total_vol += item[1];
                arr[i] = {
                    key: key_prefix + i,
                    price: item[0].toFixed(pricePrecison),
                    volume: item[1].toFixed(amountPrecison),
                    total: total_vol.toFixed(amountPrecison)
                };
            }

            return arr;
        },

        updateOrderBooks: function (depth) {
            this.order_books.bids = this.parseDepth(depth.buy, 'bid');
            this.order_books.asks = this.parseDepth(depth.sell, 'ask');
        },

        getFuturesSymbols: function () {
            const self = this;
            getSymbolManagerAsync().then((mgr) => {
                self.symbols = mgr.getSymbols(2); // 2: futures symbol
            });
        },

        setAvailableMargin: function (margin) {
            this.$refs.createOrderPanel.setAvailableMargin(margin);
            this.$refs.createOrderPanelBuy.setAvailableMargin(margin);
            this.$refs.createOrderPanelSell.setAvailableMargin(margin);
        },

        showTrade: function (up) {
            this.$refs.createOrderPanel.setOrderType(up);
            $('.trade-layout').addClass('skip-chart');
            this.showTradeEvent =true;
        },

        showChart: function () {
            $('.trade-layout').removeClass('skip-chart');
        },

        onPriceSelected: function (price) {
            // Do nothing as we don't support pending orders now.
            // this.$refs.createOrderPanel.setOrderPrice(price);
        },

        onOrderCreated: function () {
            this.$emit('order-created');
        },

        onCreateOrder: function (options) {
            this.$refs.createOrderModal.openModal(options);
        },
        initPageAsync: async function () {
            // Read symbols.
            const mgr = await getSymbolManagerAsync();
            const sidMap = {};
            if (mgr) {
                const self = this;

                let arr = [];
                $(mgr.getAllSymbols()).each((index, item) => {
                    let sym = new SymbolInfo(item);
                    arr.push(sym);

                    sidMap[sym.metadata.id] = sym;
                });

                g_symbolMap = Object.freeze(sidMap);
                self.all_symbols = arr;

                Vue.nextTick(() => {
                    // start to subscribe realtime quote data
                    self.syncQuotes();
                });
            }
        },
        syncQuotes: function () {
            const self = this;
            // clear existing timer if any
            clearTimeout(g_quoteTimerId);

            const sids = [];
            $(this.promptedSymbols).each((index, sym) => {
                sids.push(sym.metadata.id);
            });

            try {
                const temp = this.$refs.quotes.getVisibleSids();
                if (Array.isArray(temp)) {
                    for (let i = 0; i < temp.length; i++) {
                        sids.push(temp[i]);
                    }
                }
            } catch (err) {
                console.error(err);
            }

            if (sids.length > 0) {
                // Read quotes from server
                self.$http
                    .get('/api/v1/quotation/latest?symbols=' + sids.join(','))
                    .then((json) => {
                        const quotes = json.data;
                        if (quotes.length > 0) {
                            for (let i = 0; i < quotes.length; i++) {
                                const quote = quotes[i];
                                const sym = g_symbolMap[quote.id];
                                if (sym) {
                                    sym.update(quote);
                                }
                            }
                        }
                    })
                    .then(() => {
                        if (g_destoryed === false) {
                            let delay = 4000 + Math.round(Math.random() * 3000);
                            g_quoteTimerId = setTimeout(() => {
                                self.syncQuotes();
                            }, delay);
                        }
                    });
            }
        }
    }
};
</script>
<style scoped>
#futures-trade1{
    overflow: hidden;
}
.content-top {
    position: relative;
}

.content-top .dropdown {
    position: initial;
}

.side_right {
    margin: 0 !important;
    transform: none !important;
    top:40px !important;;
    background: #fff;
    color: #000;
    z-index: 9;
    overflow: hidden;
    border: 1px solid #999;
    position: absolute;
    min-width: 100px;
}

.futures-content {
    overflow: auto;
    height: 45vh;
}

.futures-content a,
.side_right .form-control {
    color: #000;
}

.bg-content input {
    border: 1px solid #E1E2E6;
}

.trade-layout a {
    font-size: 14px;
}

.futures-content a:hover {
    background: #0A2B9A;
    color: #fff;
}

.input-group {
    width: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.input-group .input-group-text,
.input-group .form-control {
    height: 25px;
    background: #fff;
    border: 1px solid #1a1c24;  
}

.input-group .input-group-text {
    width: 35px;
    height: 25px;
    background: #fff;
    border: 1px solid #1a1c24;
}

.input-group .form-control:focus {
    border: none;
}

.form-control {
    color: #7e8697;
}
.create-order-box{
    display: flex;
    flex-grow: 1;
}
#futures-trade2 {
    display: none;
}
#futures-trade4 {
    display: none;
}
#futures-trade1{
    max-width: 330px;
}
#futures-trade3{
    max-width: none;
}
.trading-chart dl, .ticker .price-container {
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#futures-change> dd {
    font-size: 12px;
}
@media screen and ( min-width :1024px) and ( max-width :1300px) {
    #futures-trade1 {
        display: none;
    }
    #futures-trade3{
        display: none;
    }
    #futures-trade2 {
        display: block;
    }
    #futures-trade4 {
        display: block;
    }
}
@media(max-width:1024px) {
    .quotes {
        display: none;
    }

    #futures-trade1 {
        display: none;
    }
    #futures-trade3{
        display: none;
    }
    #futures-trade2 {
        display: block;
    }
    #futures-trade4 {
        display: block;
    }
}
@media(max-width:767px) { 
    #futures-change{
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 9px;
    }
}
</style>
