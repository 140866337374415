<template>
    <section class="page startup-page">
        <!-- page title -->
        <div class="page-top">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h1>{{ $t(sysconfig.is_hk ? 'general.startup_hk' : 'general.startup') }}</h1>
                    </div>
                </div>
            </div>
        </div>
        <section class="pro_layout container">
            <ul class="page-top-nav mb-3">
                <!-- <li>
                    <router-link to="/startup?type=forecast">{{ $t('startup.title_forecast') }}</router-link>
                </li> -->
                <li>
                    <router-link to="/startup?type=ongoing">{{ $t('startup.title_ongoing') }}</router-link>
                </li>
                <li class="active">
                    <a href="javascript:;">{{ $t('startup.title_orders') }}</a>
                </li>
            </ul>

            <table class="table compact">
                <thead>
                    <tr>
                        <th>{{ $t('startup.orders_token') }}</th>
                        <th class="d-none d-md-table-cell">{{ $t('startup.orders_start_time') }}</th>
                        <th class="d-none d-md-table-cell">{{ $t('startup.orders_end_time') }}</th>
                        <th>{{ $t('startup.label_offer_price') }}</th>
                        <th>{{ $t('startup.orders_num_subscribed') }}</th>
                        <!-- <th>{{ $t('startup.orders_status') }}</th> -->
                        <th>{{ $t('startup.orders_num_granted') }}</th>
                        <th>{{ $t('startup.orders_time') }}</th>
                    </tr>
                </thead>
                <tbody v-if="orders">
                    <tr v-for="order in orders" :key="order.uid">
                        <td>
                            <div class="d-flex align-items-center">
                                <div v-if="order.svgIcon" class="pe-2">
                                    <startup-icon :token="order" />
                                </div>
                                <div>{{ order.token }}</div>
                            </div>
                        </td>
                        <td class="d-none d-md-table-cell">{{ new Date(order.startTime).formatDateTime() }}</td>
                        <td class="d-none d-md-table-cell">{{ new Date(order.endTime).formatDateTime() }}</td>
                        <td>{{ order.offerPrice }}</td>
                        <td>
                            {{ order.numSubscribed }}
                            <span v-if="order.status === 1" class="badge bg-primary">
                                {{ $t('startup.status_subscribed') }}
                            </span>
                            <span v-else-if="order.status === 2" class="badge bg-primary">
                                {{ $t('startup.status_processing') }}
                            </span>
                            <span v-else-if="order.status === 3" class="text-muted small">
                                {{ $t('startup.status_granted') }}
                            </span>
                            <span v-else class="text-muted small">
                                {{ $t('startup.status_other') }}
                            </span>
                        </td>
                        <!-- <td>{{ order.status }}</td> -->
                        <td>{{ order.status === 3 ? order.numGranted : '--' }}</td>
                        <td>
                            <span class="d-none d-md-block">{{ new Date(order.timeCreated).formatDateTime() }}</span>
                            <span class="d-block d-md-none">{{ new Date(order.timeCreated).formatDate() }}</span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <loading-indicator v-if="!orders" />
            <div v-else-if="orders.length === 0" class="no-data-indicator m-5">{{ $t('orders.no_orders') }}</div>
        </section>
    </section>
</template>

<script>
import StartupIcon from '../Components/_StartupIcon.vue';

export default {
    components: { StartupIcon },
    data() {
        return { orders: null };
    },

    created() {
        this.getOrdersAsync();
    },

    methods: {
        getOrdersAsync: async function () {
            const json = await $.callGetApi(this, '/api/v1/startuporder');
            if (json && json.errcode === 0) {
                this.orders = json.data;
            }
        }
    }
};
</script>
<style scoped>
.active{
    color: #000;
    font-weight: 600;
}
</style>