<template>
    <section class="page settings-page">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1>{{ $t('ga.title') }}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <loading-indicator v-if="!gaResponse" />
                    <div v-else-if="gaResponse.errcode > 0 && gaResponse.errcode < 100">
                        <!-- Completed before. -->
                        {{ $t('ga.label_linked') }}
                    </div>
                    <div v-else-if="gaResponse.errcode === 0" class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-md-6 col-lg-4" v-if="gaResponse.data">
                                    <edit-google-authenticator :qrImage="gaResponse.data.qr" :secret="gaResponse.data.otpUri" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="alert alert-error">
                        <!-- Invalid response -->
                        {{ $t('general.operation_error') }}
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style scoped>
.settings-page .card {
    background: none;
}
.settings-page h1{
    color: #000;
}
</style>
<script>
import EditGoogleAuthenticator from './Components/_EditGA.vue';
import { setProfile } from 'utilities/helper';

export default {
    components: { EditGoogleAuthenticator },
    data() {
        return {
            gaResponse: null
        }
    },

    mounted() {
        this.initAsync().catch((err) => {
            console.error(`ERROR: ${err}`);
        });
    },

    methods: {
        initAsync: async function () {
            const self = this;
            this.gaResponse = await $.callGetApi(self, '/api/v1/account/ga2');
            let secret = this.gaResponse.data.otpUri
            if(secret) {
                const parts = secret.split('secret=');
                if (parts.length > 1) {
                    this.gaResponse.data.otpUri =  parts[1].split('&')[0]; 
                }
            }
        }
    }
}
</script>