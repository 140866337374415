<template>
    <div class="section">
        <div class="section1">
            <div class="buy-coins container">
                <h2 class="title">{{$t('home.home_crypto_wallet')}}</h2>
                <div class="subtitle">{{$t('home.home_manage')}}</div>
                <div class="imgs">
                    <img src="@/assets/images/EU6/Dogecoin.png" />
                    <img src="@/assets/images/EU6/OGN.png" />
                    <img src="@/assets/images/EU6/Bitcoin.png" />
                    <img src="@/assets/images/EU6/ACT.png" />
                    <img src="@/assets/images/EU6/ETH.png" />
                    <img src="@/assets/images/EU6/GeminiDollar.png" />
                    <img src="@/assets/images/EU6/XRP.png" />
                </div>
            </div>
        </div>
        <div class="section2">
            <div class="trdes container">
                <h2 class="title">{{$t('home.home_all_round')}}</h2>
                <div class="content">
                    <div class="left">
                        <div class="img"></div>
                        <div class="text">
                            <div >{{$t('home.home_technology')}}</div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="img"></div>
                        <div class="text">
                            <div >{{$t('home.home_transactions_trading')}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <div class="section3">
            <div class="container content">
                <div class="title">{{$t('home.home_allocate_assets')}}</div>
                <div class="model">
                    <div class="left">
                        <div class="title">{{$t('home.home_portfolio')}}</div>
                        <div class="img"></div>
                    </div>
                    <div class="right">
                        <div class="title">{{$t('home.home_market_analysis')}}</div>
                        <div class="img">
                             <div v-for="(sym, index) in promptedSymbols" :key="index" class="middle-quotes">
                                <div   class="quotes"  @click="gotoTradePage(sym)">
                                    <prompted-symbol  :symbol="sym" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="currency">
                    <div class="imgs">
                        <img src="@/assets/coins/png/BTC.png" />
                        <img src="@/assets/coins/png/ETH.png" />
                        <img src="@/assets/coins/png/HHS.png" />
                        <img src="@/assets/coins/png/SOL.png" />
                        <img src="@/assets/coins/png/NEH.png" />
                    </div>
                    <div class="text">{{$t('home.home_convoy', { platform: sysconfig.name }) }}</div>
                </div>
                <div class="bottom-content"> 
                    <div class="content">
                        <div class="title">{{$t('home.home_user_experience')}}</div>  
                    </div>
                </div>
            </div>
         </div>
    </div>
</template>
<script>
import Deposit from '../../Finance/Deposit.vue'
import PromptedSymbol from './_homePromptedSymbol.vue';
export default {
  props: ['promptedSymbols'],
  components: { Deposit,PromptedSymbol},
    data() {
        return {
        }
    },
    methods: {
        gotoTradePage: function (sym) {
            let route = sym.getRoutePath();
            this.$router.push({ path: route });
        },
    }
}
</script>
<style lang="less" scoped>
.section{
    width: 100%;
    .section1{
        width: 100%;
        min-height: 300px;
        background: linear-gradient( 360deg, #FFFFFF 0%, #EFEDFD 92%, #EFEDFD 100%);
        margin-top: 30px;
        padding-top: 60px;
        .title{
            text-align: center;
            font-family: PingFang HK, PingFang HK;
            font-weight: 500;
            font-size: 28px;
            color: #0E0637;
        }
       .subtitle{
            font-family: PingFang HK, PingFang HK;
            font-weight: 400;
            font-size: 14px;
            color: #717184;
            text-align: center;
        }
        .imgs{
            width: 100%;
            height: 82px;
            background: linear-gradient( 90deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
            border-radius: 500px 500px 500px 500px;
            margin-top: 60px;
            display: flex;
            flex-direction: row;
            img{
                margin: auto;
            }
        }
    }
    .section2{
        min-height: 480px;
        width: 100%;
        margin-top: 30px;
        background: linear-gradient( 360deg, #FFFFFF 0%, #EFEDFD 67%, #FFFFFF 100%);
        .title{
            text-align: center;
            font-family: PingFang HK, PingFang HK;
            font-weight: 500;
            font-size: 28px;
            color: #0E0637;
        }
        .content{
            width: 100%;
            min-height: 200px;
            display: flex;
            margin-top: 50px;
            .left,.right{
                width: 48%;
                min-height: 350px;
                background: #fff;
                border-radius: 10px;
                border: 1px solid #EFEDFDFF;
            }
            .left{
                margin-right: 2%;
                .img{
                    width: 100%;
                    height: 65%;
                    background:url('@/assets/images/EU6/Illustration@2x.png') no-repeat;
                    background-size: 100% 100%;
                }
                .text{
                    height: 35%;
                    width: 100%;
                    padding: 10px 20px;
                    div:nth-child(1){
                        font-family: PingFang HK, PingFang HK;
                        font-weight: 500;
                        font-size: 20px;
                        color: #0E0637;
                        margin-top: 20px;
                    }
                    div:nth-child(2){
                        font-family: PingFang HK, PingFang HK;
                        font-weight: 500;
                        font-size: 14px;
                        color: #42424D;
                    }
                }
            }
            .right{
                margin-right: 2%;
                .img{
                    width: 100%;
                    height: 65%;
                    background:url('@/assets/images/EU6/Supercharge@2x.png') no-repeat;
                    background-size: 100% 100%;
                }
                .text{
                    height: 35%;
                    width: 100%;
                    padding: 10px 20px;
                    div:nth-child(1){
                        font-family: PingFang HK, PingFang HK;
                        font-weight: 500;
                        font-size: 20px;
                        color: #0E0637;
                        margin-top: 20px;
                    }
                    div:nth-child(2){
                        font-family: PingFang HK, PingFang HK;
                        font-weight: 500;
                        font-size: 14px;
                        color: #42424D;
                    }
                }
            }
        }
    }
    .section3{
        margin-top: 30px;
        width: 100%;
        min-height: 400px;
        background: linear-gradient( 360deg, #FFFFFF 0%, #EFEDFD 67%, #FFFFFF 100%);
        .title{
            text-align: center;
            font-family: PingFang HK, PingFang HK;
            font-weight: 500;
            font-size: 28px;
            color: #0E0637; 
        }
        .currency{
            width: 100%;
            min-height: 120px;
            margin-top: 20px;
            border-radius: 13px;
            background: linear-gradient( 169deg, #0E0637 0%, #0E0637FF 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            .imgs{
                width: 40%;
                text-align: right;
                padding-right: 15px;
                img{
                    width: 40px;
                    height: 40px;
                    margin-right: -6px;
                }
            }
            .text{
                width: 60%;
                font-family: PingFang HK, PingFang HK;
                font-weight: 500;
                font-size: 16px;
                color: #FFFFFF;
            }
        }
        .bottom-content{
            margin-top: 30px;
            margin-bottom: 30px;
            width: 100%;
            min-height: 340px;
            background:url('@/assets/images/EU6/CTA@2x.png') no-repeat;
            background-size: 100% 100%;
            .content{
                width: 58%;
                height: 100%;
                padding: 30px 10px 10px 20px;
                .title{
                    font-family: PingFang HK, PingFang HK;
                    font-weight: 500;
                    font-size: 20px;
                    color: #FFFFFF;
                }
                .button{
                    margin-top: 40px;
                    div{
                        font-family: PingFang HK, PingFang HK;
                        font-weight: 500;
                        font-size: 16px;
                        color: #5235E8;
                        background: #fff;
                        border: 1px solid #D6D1FAFF;
                        border-radius: 7px;
                        height: 40px;
                        width: 120px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
        .model{
           margin-top: 30px;
           width: 100%;
           min-height: 530px; 
           display: flex;
           .left{
              width: 54%; 
              margin-right: 2%;
              .title{
                width: 100%;
                padding: 10px 20px;
                height: 80px;
                font-family: PingFang HK, PingFang HK;
                font-weight: 400;
                font-size: 14px;
                color: #717184;
                text-align: left;
                margin-bottom: 20px;
              }
              .img{
                width: 100%;
                height: 444px;
                background:url('@/assets/images/EU6/Chart2x.png') no-repeat;
                background-size: 100% 100%;
              }
           }
           .right{
              width: 44%; 
              .title{
                width: 100%;
                padding: 10px 20px;
                height: 80px;
                font-family: PingFang HK, PingFang HK;
                font-weight: 400;
                font-size: 14px;
                color: #717184;
                text-align: left;
                margin-bottom: 20px;
              }
              .img{
                width: 100%;
                height: 440px;
                .middle-quotes{
                    height: 23%;
                    .quotes{
                        height: 100%;
                        background:#5235E8FF;
                        border: 1px solid #ddd;
                    }
                }
                .middle-quotes:nth-child(1){
                    height: 31%;
                    .quotes{
                        color: #fff;
                        height: 80%;
                        background:linear-gradient(169deg, #0E0637 0%, #0E0637FF 100%);
                        border-radius: 17px;
                    }
                }
                // .middle-quotes .quotes
                .middle-quotes:nth-child(2){
                    .quotes{
                        border-top-left-radius: 17px;
                        border-top-right-radius: 17px;
                    } 
                }
                .middle-quotes:nth-child(4){
                    .quotes{
                        border-bottom-left-radius: 17px;
                        border-bottom-right-radius: 17px;
                    } 
                }
              }
           }
        }
    }
}



 



@media (max-width: 992px) {

}
@media (max-width: 1024px)
{   
.section{
    .section3{
        .currency{
            .imgs{
                img{
                    width: 25px;
                    height: 25px; 
                }
            } 
        }
        .bottom-content{
            min-height: 200px;
            .content{
                min-height: 200px; 
                padding: 10px 10px;
                .title{
                    font-family: PingFang HK, PingFang HK;
                    font-weight: 500;
                    font-size: 15px;
                    color: #FFFFFF;
                }
                .button{
                    margin-top: 20px;
                    div{
                        font-family: PingFang HK, PingFang HK;
                        font-weight: 500;
                        font-size: 15px;
                        color: #5235E8;
                        background: #fff;
                        border: 1px solid #D6D1FAFF;
                        border-radius: 7px;
                        height: 40px;
                        width: 120px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

            }
            
        }
        .model{
            display: flex;
            flex-direction: column;
            min-height: 960px;
            .left{
                width: 100%;
                .img{
                    height: 300px;
                }
            }
            .right{
                margin-top: 20px;
                width: 100%;
            }
        }
   }
   .section2{
        .content{
            display: flex;
            flex-direction: column;
            .left,.right{ 
                width: 100%;
                min-height: 350px;
                margin-top:20px;
                .img{
                    height: 200px;
                }
            }
        }
   }

}    


 
}




</style>
