<template>
    <section class="page help-page pb-5">
        <div class="title-box">
            <div class="container">
                <h1 class="page-title">{{ $t('notification.notification') }}</h1>
            </div>
        </div>
        <div class="container">
            <!-- loader -->
            <loading-indicator v-if="!notifyList" />

            <div v-else class="row">
                <div class="col">
                    <!-- no public notify? -->
                    <span v-if="notifyList.length === 0" class="no-data-indicator">{{ $t('general.no_data') }}</span>
                    <div class="notify-container" v-else>
                        <div class="notify-list" v-for="(item, index) in notifyList" :key="index">
                            <div class="notify-box" @click="readNotification(item,index)" :class="item.readFlag?'readed':'read-flag'">
                                <div class="content">
                                    <div class="icon"></div>
                                    <div class="item">
                                        <div class="type"> {{ typeToText(item.type) }}</div>
                                        <pre class="msg"> {{ formatNumbersInText(item.message) }}</pre>
                                    </div>
                                </div>
                                <div class="right">
                                    <div class="read-flag">{{item.readFlag? $t('notification.read'): $t('notification.unread')}}</div>
                                    <div class="date">{{ new Date(item.timeCreated).formatDate() }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style scoped>
pre {
    word-wrap: break-word;
    white-space: pre-wrap;
}
.notify-container {
    font-size: 16px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .notify-container {
        font-size: 14px;
    }
}
</style>
<script type="text/javascript">
import VuePager from '../Components/VuePager.vue';

export default {
    components: { VuePager },

    data: function () {
        return {
            // An array of notify
            notifyList: null,

            // The current page index
            page_index: 0,

            // The hard-coded page size
            page_size: 10,

            notifyUid:0x7fffffff
        };
    },

    created() {
        this.readAllNotification();
    },

    watch: {
        '$i18n.locale': function (newVal, oldVal) {
            this.readAllNotification();
        }
    },
    
    methods: {
        formatNumbersInText(text) {
            if(this.$i18n.locale !== 'pt') {
                return text
            }
            // 使用正则表达式找到所有数字（包括小数）
            return text.replace(/-?\d+(\.\d+)?/g, (match) => {
                // 将小数点替换为逗号
                return match.replace('.', ',');
            });
        },
        readAllNotification(id = 0x7fffffff) {
            const self = this;
            this.notifyUid = id
            $.callPostApi(self, `/api/v1/notification/list?max=${id}`).then((res) => {
                if (res.errcode === 0 && res.data) {
                    self.notifyList = res.data;
                }
            });
        },
        readNotification(data,index) {
            const {readFlag,uid} = data
            if(!readFlag && uid) {
                $.callPostApi(self, `/api/v1/notification/markasread?id=${uid}`).then((res) => {
                    if (res.errcode === 0) {
                        // 修改notifyList数组的readFlag的值
                        this.$set(this.notifyList,index,{...data,readFlag:true})
                    }
                });
            }
        },

        onPageChanged: function (pageIndex) {
            this.page_index = pageIndex;
        },

        typeToText(type) {
            type = type
            switch (type) {
                case 1:
                    return this.$t("notification.registration_success");
                case 2:
                    return this.$t("notification.login");
                case 4:
                    return this.$t("notification.reset_login_pwd");
                case 8:
                    return this.$t("notification.reset_withdrawal_pwd");
                case 128:
                    return this.$t("notification.deposit");
                case 256:
                    return this.$t("notification.withdrawal_submission");
                case 512:
                    return this.$t("notification.withdrawal_cancellation");
                case 1024:
                    return this.$t("notification.withdrawal_completion");
                case 32768:
                    return this.$t("notification.subscription_submission");
                case 65536:
                    return this.$t("notification.subscription_completion");
                case 131072:
                    return this.$t("notification.subscription_unsuccessful");
                default:
                    return this.$t("notification.other_messages");
            }
        }
    }
};
</script>
<style scoped lang="less">
.title-box {
    background: #F5F5F7;
    width: 100%;
    margin: 0;
    .page-title {
        color: #333;
        font-size: 2rem;
    }
}
.no-data-indicator{
    margin-top: 6rem;
    display: flex;
    justify-content: center;
    color: #fff;
}
.notify-container {
    padding: 40px 0;
}
.notify-list {
    margin-bottom: 10px;
    .notify-box {
        padding: 12px 24px 12px 20px;
        display: flex;
        justify-content:space-between;
        border-bottom: 1px solid #EDEEEF;
        .icon {
            flex: 0 0 19px; 
            padding: 4px;
            width: 19px;
            height: 20px;
            margin-right: 10px;
            background: url('@/assets/images/notification/icon-notify-msg.png') no-repeat center;
            background-size: 100%;
        }
        &.read-flag {
            background: #F8F9FC ;
            border-radius: 0px 0px 0px 0px;
            border-bottom: 1px solid #F8F9FC;
            cursor: pointer;
            transition: all 0.4s;
            &:hover {
                background: #EDF5F7;
                border-bottom: 1px solid #4D9EAF;
            }
            .icon {
                background: url('@/assets/images/notification/icon-notify-msg-read.png') no-repeat center;
                background-size: 100%;
            }
            .right {
                .read-flag {
                    color: #4D9EAF;
                }
            }
            .content {
                .type {
                    color: #4D9EAF;
                }
            }
        }
        .content {
            display: flex;
            flex: 1;
            padding-right: 100px;
            .type {
                padding-bottom: 10px;
            }
        }
        .right {
            text-align: right;
            .read-flag {
                padding-bottom: 10px;
                color: #666;
            }
        }
    }
}

@media (max-width: 1024px){
    .notify-list {
        .notify-box {
            display: block;
            .content {
                padding-right: 0;
            }
            .right {
                padding: 20px 0 0;
            }
        }
    }
}
</style>