<template>
    <section>
        <table class="table list-table stock-list-table text-sm">
            <thead>
                <tr>
                    <th>{{ $t('balance.title_time') }}</th>
                    <th>{{ $t('balance.title_balance') }}</th>
                    <th>{{ $t('balance.title_commissions') }}</th>
                    <th>{{ $t('balance.title_description') }}</th>
                </tr>
            </thead>
            <tbody v-if="records">
                <tr v-for="rec in records" v-bind:key="rec.id">
                    <td>{{ new Date(rec.timeCreated).formatDateTime() }}</td>
                    <td>{{ rec.currentBalance }}</td>
                    <td>
                        <span v-if="rec.balanceChange >= 0" class="color-up">+{{ rec.balanceChange }}</span>
                        <span v-else class="color-down">{{ rec.balanceChange }}</span>
                    </td>
                    <td>{{ rec.description }}</td>
                </tr>
            </tbody>
        </table>

        <div v-if="hasMoreRecords">
            <a href="javascript:;" class="btn btn-default btn-block text-muted" v-on:click="queryData()">{{ $t('balance.label_next_page') }}</a>
        </div>

        <!-- status indicator -->
        <loading-indicator v-if="!records" />
        <div v-else-if="records.length === 0" class="no-data-indicator m-5">{{ $t('general.no_data') }}</div>
    </section>
</template>



<style scoped>
.btn {
    margin: 1rem 0;
}
.table td,
.table th {
    padding-left: 2px;
    padding-right: 2px;
}
</style>

<script>
export default {
    data() {
        return {
            records: null,

            hasMoreRecords: false,
            until_uid: 0
        };
    },

    created() {
        this.queryData();
    },

    methods: {
        ///////////////////////////////////////////////////////////////////////////////////////////
        // Query records
        ///////////////////////////////////////////////////////////////////////////////////////////
        queryData: function () {
            const self = this;
            self.records = null;

            $.callPostApi(self, '/api/v1/finance/history?token=INCT&uid=' + encodeURIComponent(self.until_uid))
                .then((json) => {
                    self.records = json.data;
                    if (json.data && json.data.length) {
                        self.until_uid = json.data[json.data.length - 1].uid;
                    }
                    self.hasMoreRecords = json.data.length === json.page_size;
                })
                .catch((err) => {
                    console.error(`ERROR: ${err}`);
                    $.top_error(self.$t('general.http_error'));
                });
        }
    }
};
</script>