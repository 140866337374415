<template>
    <section class="page home-page">
        <section class="pro_layout">
            <loading-indicator v-if="!symbols" />
            <div v-else>
                <div class="home-top">
                    <top-banners v-if="context.homeConfig" :home_config="context.homeConfig" />
                </div>

                <!-- top notice -->
                <div class="page-part" v-if="context.homeConfig && context.homeConfig.promptedNotices && context.homeConfig.promptedNotices.length">
                    <home-notices :promptedNotices="context.homeConfig.promptedNotices" />
                </div>

                <!-- major nav icons for sm screens -->
                <div class="d-block d-md-none sm-navs">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <router-link to="/user/login">
                                    <svg class="svg-icon" viewBox="0 0 256 256">
                                        <use xlink:href="/dist/svg/icons.svg#v4.2-login" />
                                    </svg>
                                    {{ $t('general.login') }}/{{ $t('general.register') }}
                                </router-link>
                            </div>
                            <div class="col">
                                <!-- Display a tab for the savings feature when it was enabled. -->
                                <router-link v-if="sysconfig.savingsEnabled" to="/saving">
                                    <svg class="svg-icon" viewBox="0 0 256 256">
                                        <use xlink:href="/dist/svg/icons.svg#v4.2-wallet" />
                                    </svg>
                                    {{ $t('home.header_saving', { platform: sysconfig.name }) }}
                                </router-link>
                                <router-link v-else to="/user/balance">
                                    <svg class="svg-icon" viewBox="0 0 256 256">
                                        <use xlink:href="/dist/svg/icons.svg#v4.2-wallet" />
                                    </svg>
                                    {{ $t(sysconfig.is_hkmy ? 'home.header_buy_crypto_my' : 'home.header_buy_crypto') }}
                                </router-link>
                            </div>
                            <div class="col">
                                <router-link to="/startup">
                                    <svg class="svg-icon" viewBox="0 0 256 256">
                                        <use xlink:href="/dist/svg/icons.svg#v4.2-assets" />
                                    </svg>
                                    {{ $t(sysconfig.is_hk ? 'general.startup_hk' : 'general.startup') }}
                                </router-link>
                            </div>
                            <div class="col">
                                <router-link to="/notices">
                                    <div class="svg-box"> <svg class="svg-icon notice-svg" viewBox="0 0 256 256">
                                            <use class="use-svg" xlink:href="/dist/svg/icons.svg#v4.2-notice" />
                                        </svg>
                                    </div>
                                    {{ $t('notices.notice_title') }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <inline-svg-icons :symbols="symbols" />
                <section class="home-section">
                    <div class="container">
                        <div class="information" >
                           <h2 class="title">{{$t('home.home_main_title')}}</h2>
                           <div class="subtitle">{{$t('home.home_subtitle')}}</div>
                        </div>
                        <div class="row quotes">
                            <div class="col">
                                <h2 class="table-title">
                                    {{ $t('home.home_precision_trading') }}
                                </h2>
                                <div class="main-block-wrapper">
                                    <home-quotes ref="quotes" :symbols="symbols" />
                                </div>
                                <div class="more">
                                    <router-link to="/quotes" class="d-flex align-items-center">
                                        {{ $t('home.label_more') }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <introduction :promptedSymbols="prompted_symbols" />

                <!-- advantages introduction -->
                <!-- <features /> -->

                <!-- posts -->
                <!-- <home-posts v-if="homeConfig" :posts="homeConfig.posts" /> -->
                <partner-links v-if="context && context.homeConfig && context.homeConfig.spl === true" />
            </div>
        </section>
    </section>
</template>

<style scoped lang="less">
.home-section{
   background: linear-gradient( 360deg, #FFFFFF 0%, #EFEDFD 67%, #FFFFFF 100%);
}
.information{
    margin-bottom: 30px;
    .title{
        text-align: center;
        font-family: PingFang HK, PingFang HK;
        font-weight: 500;
        font-size: 28px;
        color: #0E0637;
        margin-top: 20px;
    }
    .subtitle{
        font-family: PingFang HK, PingFang HK;
        font-weight: 400;
        font-size: 14px;
        color: #717184;
        text-align: center;
    }
    .imgs{
        margin-top: 30px;
        width: 100%;
        height: 60px;
        background: url('@/assets/images/EU6/Companies@2x.png') no-repeat;
        background-size:100% 100%;
    }
}
.quotes{
    margin-top: 20px;
}
.table-title{
    text-align: center;
    margin-bottom: 30px;
    margin-top: 30px;
}
.container.more{
    display: flex;
    align-items: center;
    justify-content: center;
} 
.container .more a{
    width: 150px;
    height: 50px;
    border-radius: 20px;
    border: 1px solid #BAB9D0;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.container .more{
    display: flex;
    align-items: center;
    justify-content: center; 
    margin-top: 30px;
}
 /* ** */
.sm-navs {
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
}
.sm-navs a {
    color: #000;
    font-size: 12px;
    display: inline-block;
    width: 100%;
    word-wrap: break-word;
}

.sm-navs svg.svg-icon {
    display: block;
    height: 1.5rem;
    width: auto;
    margin: auto;
    margin-bottom: 0.5rem;
    fill: var(--primary-base);
}
.sm-navs .container .row >.col {
    width: 25%;
}
.svg-box {
    height: 1.5rem;
    position: relative;
    margin-bottom: 0.5rem;
}

.sm-navs svg.svg-icon.notice-svg {
    height: 2.8rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.vertical-line{
    margin-right: 10px;
}
.top-up{
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 12px;
    color: #000; 
    cursor: pointer;
}
.inner,.inner-content{
    width: 70%;
    min-height: 20px;
}
.inner{
    font-weight: 600;
    font-size: 12px;
}
.inner-content{
    font-size: 11px;
}
@media screen and ( min-width :768px) and ( max-width :1024px) {
    .d-md-none {
        display: block !important;
    }
}
@media (max-width: 1024px) {
    .imgs{
        flex-direction: column;
        align-items: center;
    }
    .information{
        .imgs{
            height: 35px;
        }
    }
    .imgs >div{
        width: 70%;
    }
    .imgs >div:nth-child(2),.imgs >div:nth-child(1),.imgs >div:nth-child(3){
       background-size:100% 100%; 
       height: 160px;
       margin-bottom: 20px;
    }
}
</style>

<script type="text/javascript">
import SymbolList from './Components/SymbolList.vue';
import TopBanners from './Components/TopBanners-4.2.vue';
import HomeNotices from './Components/HomeNotices.vue';
import HomePosts from './Components/HomePosts.vue';
import PromptedSymbols from './Components/PromptedSymbols.vue';
import Introduction from './Components/Introduction-4.2.vue';
import HomeQuotes from './Components/HomeQuotes.vue';
import HomeCarousel from './Components/HomeCarousel.vue';
import InlineSvgIcons from '../Components/_InlineSvgIcons.vue';
import PartnerLinks from './Components/_PartnerLinks-4.2.vue';
import '@/assets/svg/icon-login.svg';
import '@/assets/svg/icon-wallet.svg'
import '@/assets/svg/icon-assets.svg';;
import '@/assets/svg/icon-invite.svg';
import '@/assets/svg/icon-notice.svg';


import '@/assets/svg/v4.2/v4.2-login.svg';
import '@/assets/svg/v4.2/v4.2-wallet.svg';
import '@/assets/svg/v4.2/v4.2-assets.svg';
import '@/assets/svg/v4.2/v4.2-notice.svg';

import { SymbolInfo } from 'utilities/SymbolInfo.js';
import { getSymbolManagerAsync, getAppContext } from 'utilities/helper';

let g_symbolMap = {};
let g_destoryed = false;
let g_quoteTimerId = 0;

export default {
    components: { TopBanners, SymbolList, HomeNotices, HomePosts, Introduction, PromptedSymbols, HomeQuotes, HomeCarousel, InlineSvgIcons, PartnerLinks},

    data() {
        return {
            // All symbols.
            symbols: null,
            // App context
            context: null
        };
    },

    computed: {
        // return a list of prompted symbols
        promptedSymbols: function () {
            let arr = this.symbols.filter(function (s) {
                return s.metadata.prompted && !s.metadata.testOnly;
            });
            return arr;
        },
        prompted_symbols: function () {
            let list=["BTC" ,"ETH" ,"SOL" ,"AVAX" ];
            let seenNames = new Set();
            let arr = this.symbols.filter(function (s) {
                if (s.metadata.baseSymbol && !seenNames.has(s.metadata.name)&&list.includes(s.metadata.baseSymbol) ) {
                    seenNames.add(s.metadata.name);
                    return true;
                }
                return false;
            });
            return arr.slice(0, 4);
        }
    },

    created() {
        g_destoryed = false;
        this.context = getAppContext();
        // clear all existing symbols
        g_symbolMap = {};
        this.initPageAsync();
    },

    beforeDestroy() {
        g_destoryed = true;

        // clear all timers
        clearTimeout(g_quoteTimerId);
    },

    methods: {
        initPageAsync: async function () {
            // Read symbols.
            const mgr = await getSymbolManagerAsync();
            const sidMap = {};
            if (mgr) {
                const self = this;

                let arr = [];
                $(mgr.getAllSymbols()).each((index, item) => {
                    let sym = new SymbolInfo(item);
                    arr.push(sym);

                    sidMap[sym.metadata.id] = sym;
                });

                g_symbolMap = Object.freeze(sidMap);
                self.symbols = arr;

                Vue.nextTick(() => {
                    // start to subscribe realtime quote data
                    self.syncQuotes();
                });
            }
        },

        gotoTradePage: function (sym) {
            let route = sym.getRoutePath();
            this.$router.push({ path: route });
        },

        syncQuotes: function () {
            const self = this;
            // clear existing timer if any
            clearTimeout(g_quoteTimerId);

            const sids = [];
            $(this.prompted_symbols).each((index, sym) => {
                sids.push(sym.metadata.id);
            });

            try {
                const temp = this.$refs.quotes.getVisibleSids();
                if (Array.isArray(temp)) {
                    for (let i = 0; i < temp.length; i++) {
                        sids.push(temp[i]);
                    }
                }
            } catch (err) {
                console.error(err);
            }

            if (sids.length > 0) {
                // Read quotes from server
                self.$http
                    .get('/api/v1/quotation/latest?symbols=' + sids.join(','))
                    .then((json) => {
                        const quotes = json.data;
                        if (quotes.length > 0) {
                            for (let i = 0; i < quotes.length; i++) {
                                const quote = quotes[i];
                                const sym = g_symbolMap[quote.id];
                                if (sym) {
                                    sym.update(quote);
                                }
                            }
                        }
                    })
                    .then(() => {
                        if (g_destoryed === false) {
                            // always restart timer
                            let delay = 4000 + Math.round(Math.random() * 3000);
                            g_quoteTimerId = setTimeout(() => {
                                self.syncQuotes();
                            }, delay);
                        }
                    });
            }
        }
    }
};
</script>
