<template>
    <section class="page login-page layout-login">
        <loading-indicator v-if="!config" />
        <template v-else>
            <create-account-component v-if="config.signupEnabled === true" :config="config" :regions="regions" :referral="id" />
            <div class="container" v-else>
                <div class="row">
                    <div class="col">
                        <div class="alert alert-info mt-5">
                            <h1>系统升级</h1>
                            <p>为了给您提供更好的服务，我们正对现有系统进行升级。升级过程中，暂停新账号注册。</p>

                            <hr />
                            <router-link to="/user/login">已有账号？点击这里登录</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </section>
</template>

<script>
import { getConfigAsync } from 'utilities/helper';
import CreateAccountComponent from './Components/CreateAccount.vue';

export default {
    components: { CreateAccountComponent },
    props: ['id'],

    data() {
        return {
            config: null,

            // All supported regions
            regions: null
        };
    },

    created() {
        this.initAsync();
    },

    watch: {
        '$i18n.locale': function () {
            this.reloadRegionsAsync();
        }
    },

    methods: {
        initAsync: async function () {
            const self = this;

            let config = await getConfigAsync();
            let resp = await $.callGetApi(self, '/api/v1/config/regions');
            if (resp) {
                self.regions = resp.data || [];
                self.config = config;
            }
        },

        reloadRegionsAsync: async function () {
            // Read a list of supported regions.
            const resp = await $.callGetApi(self, '/api/v1/config/regions');
            if (resp && resp.data) {
                this.regions = resp.data;
            }
        }
    }
};
</script>
<style scoped>
@media (max-width: 1024px) {
    .page {
        height: 100%;
    }
}
</style>