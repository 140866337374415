<template>
    <div class="banner">
        <loading-indicator v-if="!banners" />

        <div v-if="banners" id="top-banner" class="carousel slide d-none d-md-block" data-bs-ride="carousel">
            <!-- indicators -->
            <div class="carousel-indicators">
                <!-- <button data-bs-target="#top-banner" data-bs-slide-to="0" class="active"></button> -->
                <template v-if="banners.length > 0">
                    <button v-for="(banner, index) in banners" data-bs-target="#top-banner" :key="index" :data-bs-slide-to="index" :class="{ active: index === 0 }"></button>
                </template>
            </div>

            <!-- banners -->
            <div class="carousel-inner">
                <!-- <div class="carousel-item active">
                    <first-carousel-item />
                </div> -->
                <div class="carousel-item" v-for="(banner, index) in banners" :key="index" :class="{ active: index === 0 }">
                    <div class="h-100" :style="'background-image: url(' + banner + ')'"></div>
                </div>
            </div>
        </div>

        <div v-if="mobileBanners" id="top-banner-sm" class="carousel slide d-lg-none d-md-none" data-bs-ride="carousel">
            <!-- indicators -->
            <div class="carousel-indicators">
                <!-- <button data-bs-target="#top-banner-sm" data-bs-slide-to="0" class="active"></button> -->
                <template v-if="mobileBanners.length > 0">
                    <button v-for="(banner, index) in mobileBanners" data-bs-target="#top-banner-sm" :key="index" :data-bs-slide-to="index" :class="{ active: index === 0 }"></button>
                </template>
            </div>
            <!-- banners -->
            <div class="carousel-inner">
                <!-- <div class="carousel-item active">
                    <first-carousel-item />
                </div> -->
                <div class="carousel-item" v-for="(banner, index) in mobileBanners" :key="index" :class="{ active: index === 0 }">
                    <div class="h-100" :style="'background-image: url(' + banner + ')'"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.carousel-indicators [data-bs-target] {
    /* top: 15rem;
    background-color: var(--primary-base); */
    width: 1rem;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    opacity: 0.25;
}

.carousel-indicators .active {
    opacity: 0.5;
}
</style>

<script>
import FirstCarouselItem from './_FirstCarouselItem-4.1.vue';
import { Carousel } from 'bootstrap';

export default {
    components: { FirstCarouselItem },
    props: ['home_config'],

    data() {
        return { banners: null, mobileBanners: null, carousel: null };
    },

    watch: {
        home_config: function () {
            this.reset();
        }
    },

    mounted() {
        this.reset();
    },

    beforeDestroy() {
        const obj = this.carousel;
        if (obj) {
            console.log(`## Dispose the carousel.`);
            obj.dispose();
        }
    },

    methods: {
        reset: function () {
            this.banners = this.home_config.banners || [];
            this.mobileBanners = this.home_config.mobileBanners || [];

            const self = this;
            const run_carousel = () => {
                if (self.banners.length > 0) {
                    self.carousel = new Carousel(document.querySelector('#top-banner'), {
                        interval: 5000,
                        ride: 'carousel'
                    });
                }

                if (self.mobileBanners.length > 0) {
                    self.carousel = new Carousel(document.querySelector('#top-banner-sm'), {
                        interval: 5000,
                        ride: 'carousel'
                    });
                }
            };
            // run_carousel();
            Vue.nextTick(() => {
                run_carousel();
            });
        }
    }
};
</script>
<style scoped>
@media screen and ( min-width :768px) and ( max-width :1024px) {
    #top-banner{
        display: none  !important;
    }
    #top-banner-sm{
        display: block  !important;
    }
    .pro_layout .banner .carousel-item>* {
        background-size: 100% 100%;
   }
}
</style>>