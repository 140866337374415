<template>
    <div ref="closeAllModal" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel">{{ $t('futures.close_all') }}</h4>
                </div>
                <form method="post" action="javascript:;" @submit="doCloseAll">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col">
                                {{ $t('futures.close_all_confirm') }}
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-bs-dismiss="modal">{{ $t('general.cancel') }}</button>
                        <button type="submit" class="btn btn-primary footer-submit" :disabled="submitting" :class="{ 'btn-loading': submitting }">{{ $t('futures.close_all') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
    data() {
        return {
            submitting: false,

            // The popup confirmation modal.
            popupModal: null
        };
    },

    mounted() {
        this.popupModal = new Modal(this.$refs.closeAllModal, { dismissible: false });
    },

    beforeDestroy() {
        const m = this.popupModal;
        if (m) {
            m.dispose();
        }
    },

    methods: {
        openModal: function () {
            this.popupModal.show();
        },

        doCloseAll: function () {
            this.submitting = true;
            const self = this;

            setTimeout(() => {
                $.callPostApi(self, '/api/v1/futures/closeall')
                    .then(function (json) {
                        $.top_alert(json.errmsg || self.$t('general.submitted'));

                        // close the popup modal when finished
                        try {
                            self.popupModal.hide();
                        } catch (err) {
                            console.error('Failed to close the popup modal: ' + err);
                        }

                        // resync open orders shortly
                        setTimeout(() => {
                            self.$emit('closed');
                        }, 500);
                    })
                    .catch(function (err) {
                        console.error(`ERROR: ${err}`);
                        $.top_error(self.$t('general.operation_error'));
                    }).then(() => {
                        self.submitting = false;
                    });
            }, 500);
        }
    }
};
</script>
<style scoped>
.footer-submit{
    background:  #2E86FE;
}
</style>