<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="home_quotes">
                    <loading-indicator v-if="!symbols" />
                    <template v-else>
                        <symbol-list ref="sym_list" :symbols="symbols" :typeFilter="3" @symbol-selected="gotoTradePage" />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

hr {
    background-color: var(--divider-secondary);
    opacity: 0.1;
}
.home_quotes{
    background: #FFFFFF;
    box-shadow: 0px 7px 23px -1px #E4D3F6;
    border-radius: 4px;
}
</style>

<script>
import SymbolList from './SymbolList.vue';

export default {
    components: { SymbolList },

    props: ['symbols'],

    methods: {
        gotoTradePage: function (sym) {
            let route = sym.getRoutePath();
            this.$router.push({ path: route });
        },

        getVisibleSids: function () {
            return this.symbols ? this.$refs.sym_list.getVisibleSids() : [];
        }
    }
};
</script>