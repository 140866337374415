<template>
    <section>
        <table class="table list-table stock-list-table text-sm">
            <thead>
                <tr>
                    <th>{{ $t('referrals.label_user_name') }}</th>
                    <th>{{ $t('referrals.label_user_real_name') }}</th>
                    <th>{{ $t('referrals.label_user_reg_time') }}</th>
                </tr>
            </thead>
            <tbody v-if="users">
                <tr v-for="u in users" v-bind:key="u.id">
                    <td>{{ u.name }}</td>
                    <td>
                        {{ u.realName }}

                        <!-- Show a badge for not verified users. -->
                        <span v-if="u.verifiedLevel < sysconfig.idLevel" class="badge bg-danger">{{ $t('referrals.label_user_not_verified') }}</span>
                    </td>
                    <td>{{ new Date(u.timeCreated).formatDateTime() }}</td>
                </tr>
                <tr v-if="hasMoreRecords">
                    <td colspan="3">
                        <button class="btn btn-default btn-block text-muted" v-on:click="queryData()">{{ $t('balance.label_next_page') }}</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- status indicator -->
        <loading-indicator v-if="!users" />
        <div v-else-if="users.length === 0" class="no-data-indicator m-5">{{ $t('general.no_data') }}</div>
    </section>
</template>


<style scoped>
.btn {
    margin: 1rem 0;
}

.table td,
.table th {
    padding-left: 2px;
    padding-right: 2px;
}
</style>

<script>
export default {
    data() {
        return {
            users: null,

            hasMoreRecords: false,
            offset: 0
        };
    },

    created() {
        this.queryData();
    },

    methods: {
        ///////////////////////////////////////////////////////////////////////////////////////////
        // Query users
        ///////////////////////////////////////////////////////////////////////////////////////////
        queryData: function () {
            const self = this;
            self.users = null;

            self.hasMoreRecords = false;
            $.callPostApi(self, '/api/v1/referral/prompted?offset=' + encodeURIComponent(self.offset))
                .then((json) => {
                    if (json.errcode === 0) {
                        self.users = json.data;
                        self.offset = json.offset;
                        self.hasMoreRecords = json.data.length === json.page_size;
                    }
                })
                .catch((err) => {
                    console.error(`ERROR: ${err}`);
                    $.top_error(self.$t('general.http_error'));
                });
        }
    }
};
</script>